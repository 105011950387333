import React, { useEffect, useState } from 'react'
import { useIntl } from 'react-intl'
import { Link, navigate } from 'gatsby'
import { Layout, Button } from 'components'
import { Container, Desc, Title } from './style'

const RegisterSuccessLayout = ({ location }) => {

  const intl = useIntl()

  const [hasPermission, setHasPermission] = useState(false)

  // redirect to home page if there is no permission
  useEffect(() => {

    // handle browser navigate back
    window.onpopstate = () => {
      setHasPermission(false)
      navigate('/')
    }

    if(!location.state?.permission) {
      navigate('/')
      setHasPermission(false)
    }
    else if(location.state?.permission) setHasPermission(true)
    else setHasPermission(false)
 
  }, [location])

  // add confirmation alert when user try leave register success page
  useEffect(() => {
    window.addEventListener('beforeunload', alertUser)
    return () => {
      window.removeEventListener('beforeunload', alertUser)
    }
    
  }, [])

  const alertUser = (e) => {
    e.preventDefault()
    e.returnValue = ''
  }

  return(
    <Layout>
      {
        hasPermission && (
          <Container>
            <Title>
              { intl.formatMessage({ id: 'df60b21c9' }) }
            </Title>
            <Desc>
              { intl.formatMessage({ id: 'a6c108bbd' }) }
            </Desc>
            <Link to="/">
              <Button style={{ width: '19em' }} type="warning">
                { intl.formatMessage({ id: '315e55b1a' }) }
              </Button>
            </Link>
          </Container>
        )
      }
    </Layout>
  )
}

export default RegisterSuccessLayout
