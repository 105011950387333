import styled from 'styled-components'
import { darkBlueGrey } from 'components/baseColor'
import { IS_FRAME_MOBILE } from 'utils'

export const Container = styled.div`
  background-image: url("/eye-illustration.png");
  height: calc(100vh - 77px);
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  ${IS_FRAME_MOBILE} {
    background-position: top;
    align-items: center;
  }
`

export const Title = styled.h1`
  margin: 0;
  padding: 0;
  font-size: 33px;
  color: ${darkBlueGrey};
`

export const Desc = styled.p`
  margin: 0;
  width: 35%;
  text-align: center;
  color: ${darkBlueGrey};
  margin-top: 5px;
  margin-bottom: 27px;
  font-size: 1.5em;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.43px;

  ${IS_FRAME_MOBILE} {
    text-align: center;
    width: 70%;
    font-size; 0.9rem;
    font-weight: 500;
    margin-top: 0.75rem;
  }
`
